import React, { Component } from 'react';
import * as L from 'leaflet';
import MapHelper from './../../helpers/MapHelper';
import * as turf from '@turf/turf';

import markerIcon from './../../assets/img/arrows.png';

class MapContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };

        this.defaults = {
            center: {lat: 50.0365167819264, lng: 36.19964674115182},
            zoom: 10
        };

        this.mapContainer = React.createRef();
    }


    componentDidMount() {
        if (window.appComponent.state.gmAPILoaded) {
            this.initMap();
        } else {
            window.addEventListener('google-maps-loaded', () => this.initMap());
        }
    }


    initMap() {
        this.map = L.map(this.mapContainer.current, {
            attributionControl: false
        });
        this.map.off('dblclick');

        let that = this;
        this.map.on('moveend', function() {
            //console.log(that.map.getCenter());
        });

        this.helper = new MapHelper(this.map);

        this.createTileLayers();

        this.map.setView(this.defaults.center, this.defaults.zoom);

        this.drawOnMap();
    }



    extendLatLng(latLng) {
        const center = turf.point([this.defaults.center.lat, this.defaults.center.lng]);
        const originalDestination = turf.point([latLng.lat, latLng.lng]);
        const bearing = turf.bearing(center, originalDestination);
        const newDestinationFeature = turf.destination(center, 70, bearing, {units: 'kilometers'});
        const newDestination = turf.getCoord(newDestinationFeature);

        return L.latLng(newDestination[0], newDestination[1]);
    }



    putOnMap(originalCenter, color, label) {
        let center = this.extendLatLng(originalCenter);
        L.polyline([this.defaults.center, center], {
            color: color,
            weight: 2,
            opacity: '0.9'
        }).addTo(this.map);
        L.popup({autoClose: false}).setLatLng(center).setContent('<p style="text-align: center;">'+label+'</p>').openOn(this.map);
    }



    drawOnMap() {
        L.circle(this.defaults.center, 30000, {
            color: '#689F38',
            weight: 3,
            opacity: '0.7',
            fillColor: '#689F38',
            fillOpacity: '0.2',
        }).addTo(this.map);
        L.circle(this.defaults.center, 11000, {
            color: '#689F38',
            weight: 3,
            opacity: '0.7',
            fillColor: '#689F38',
            fillOpacity: '0.2',
        }).addTo(this.map);

        this.putOnMap({lat: 50.00428824774501, lng: 36.22826725244523}, '#F9A825', '<b>ХНУ ім. В.Н. Каразіна</b><br />Головний корпус');
        this.putOnMap({lat: 50.00713072021319, lng: 36.23080931603909}, '#D84315', '<b>ХНУ ім. В.Н. Каразіна</b><br />Північний корпус');
        this.putOnMap({lat: 50.04359301341069, lng: 36.23511493206025}, '#00796B', '<b>Телевізійна вишка</b>');
        this.putOnMap({lat: 49.9713657314517, lng: 36.106221377849586}, '#1565C0', '<b>Труба ТЕЦ-5</b>');
        this.putOnMap({lat: 50.03898632474703, lng: 36.21782541275025}, '#00838F', '<b>Лівий будинок Монте Плаза</b>');
        this.putOnMap({lat: 49.960026493285504, lng: 36.221862137317665}, '#283593', '<b>Будинок батьків</b>');
        this.putOnMap({lat: 50.049887144743, lng: 36.168830841779716}, '#FFA000', '<b>Міст</b>');
        this.putOnMap({lat: 50.0281965371345, lng: 36.25394739210606}, '#283593', '<b>Тетра</b>');
        this.putOnMap({lat: 49.95949783302983, lng: 36.307463496923454}, '#1565C0', '<b>Дашини батьки</b><br />Напрям на вул. Культури 22В');
        this.putOnMap({lat: 50.04284435430666, lng: 36.223027557134635}, '#00838F', '<b>Церква на пр. Науки</b>');
        //this.putOnMap({lat: 50.032003530074014, lng: 36.21447131037713}, '#D32F2F', '<b>1231231</b>');

        let marker = L.marker({lat: 50.032003530074014, lng: 36.21447131037713}, {
            icon: L.icon({
                iconUrl: markerIcon,
                iconSize: [32, 32],
                iconAnchor: [16, 16]
            }),
            draggable: true
        }).addTo(this.map),

        draggablePolyline = L.polyline([this.defaults.center, this.extendLatLng(marker.getLatLng())], {
            color: '#D32F2F',
            weight: 10,
            opacity: 0.5
        }).addTo(this.map),
        that = this;

        marker.on('drag', function(e) {
            let center = that.extendLatLng(e.latlng);
            draggablePolyline.setLatLngs([that.defaults.center, center]);
        });












    }



    createTileLayers() {
        this.tilesMapbox = this.helper.createMapboxTilesLayer();
        this.tilesGoogleMaps = this.helper.createGoogleTilesLayer();

        this.updateMapType();
    }



    updateMapType(mapType) {
        if (this.map === undefined) {return;}

        if (mapType == undefined) {mapType = this.props.mapType;}
        this.mapType = mapType;

        const layers = {
            google: this.tilesGoogleMaps,
            mapbox: this.tilesMapbox
        };

        if (!this.map.hasLayer(layers[mapType])) {
            for (const layer of Object.values(layers)) {
                this.map.removeLayer(layer);
            }

            layers[mapType].addTo(this.map);
            if (mapType == 'google') {
                const onControlsReady = (function() {
                    this.map.setView(this.defaults.center, this.defaults.zoom);
                }).bind(this);
                this.tilesGoogleMaps.once('controls_ready', onControlsReady);
            }
        }
    }


    render() {
        return (
            <div id="mapContainer" ref={this.mapContainer}>

            </div>
        );
    }
}

export default MapContainer;
