import React, { Component } from 'react';
import MapContainer from './MapContainer';


class Map extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mapType: props.mapType
        };
    }

    render() {
        return (
            <div className="map-panel">
                <MapContainer
                    mapType={this.state.mapType}
                />
            </div>
        );
    }
}

export default Map;
