import React, { Component } from 'react';

import Map from './Components/Map/Map';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            gmAPILoaded: false,
            panelTypesAvailable: [],
            project: {}
        };

        window.appComponent = this;
    }


    componentDidMount() {
        this.includeGoogleMapsAPI();
    }


    includeGoogleMapsAPI() {
        const script = document.createElement("script");

        const params = {
            v: '3.exp',
            libraries: 'places',
            callback: 'googleMapsAPILoadedCallback',
            key: process.env.REACT_APP_GOOGLEMAPS_TOKEN
        };
        let paramsArr = [];
        for (const [key, value] of Object.entries(params)) {paramsArr.push(key+'='+value);}
        script.src = "https://maps.googleapis.com/maps/api/js?"+paramsArr.join('&');
        script.async = true;
        script.defer = true;

        document.body.appendChild(script);

        const callbackScript = document.createElement("script");
        callbackScript.innerHTML = 'function googleMapsAPILoadedCallback() {window.appComponent.googleMapsAPILoadedCallback();}';
        document.body.appendChild(callbackScript);
    }


    googleMapsAPILoadedCallback() {
        this.setState({
            gmAPILoaded: true
        }, () => {
            const event = new Event("google-maps-loaded");
            window.dispatchEvent(event);
        });
    }


    render() {
        return (
            <div className="cover">
                <Map
                    mapType="google"
                />
            </div>
        );
    }
}

export default App;