import React, { Component } from 'react';
import * as L from "leaflet";
import "leaflet.gridlayer.googlemutant";

class MapHelper {
    constructor(map) {
        this.map = map;
    }


    setDefaultParameters(mapType) {
        let defaults = {};

        defaults.center = L.latLng(50.0365412,36.1995992);

        this.defaults = defaults;
        return defaults;
    }



    createMapboxTilesLayer() {
        let tilesMapbox = L.tileLayer('https://api.mapbox.com/styles/v1/vkomlev/cl10ok0dh000314pbh4sq7vw4/tiles/256/{z}/{x}/{y}@2x?access_token=' + process.env.REACT_APP_MAPBOX_TOKEN + '&{randint}', {
            attribution: '',
            tileSize: 512,
            detectRetina: true
        });

        return tilesMapbox;
    }



    createGoogleTilesLayer() {
        let tilesGoogleMaps = L.gridLayer.googleMutant({
            type: "hybrid", // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
            styles: [
              {
                "featureType": "administrative",
                "elementType": "geometry",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              },
              {
                "featureType": "poi",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              },
              {
                "featureType": "road",
                "elementType": "labels.icon",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              },
              {
                "featureType": "transit",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              }
            ]
        });

        return tilesGoogleMaps;
    }
}

export default MapHelper;